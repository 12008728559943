
import Vue from "vue"

const ClientInvitations = () => import("@/components/customer/ClientInvitations.vue").then(m => m.default || m)

export default Vue.extend({
	name: "Registrations",
    components: {
        ClientInvitations,
       
    },
	watch: {

	},
	computed: {

	},
	methods: {

	},
})
